<template>
  <Fieldset legend="แบบฟอร์มนำเข้าข้อมูลสุขภาพ">
    <div class="p-fluid p-grid p-jc-start">
      <div class="p-col-12 p-sm-2">
        <Button
          label="ย้อนกลับ"
          icon="pi pi-arrow-left"
          class="p-button-sm p-button-warning"
          @click="() => $router.back(-1)"
        />
      </div>
      <div class="p-col-12 p-sm-2">
        <Dropdown
          v-model="selectedTable"
          :options="table"
          optionLabel="name"
          optionValue="value"
        />
      </div>
    </div>
    <!-- 10485760 : 10 MB -->

    <UploadFile
      name="files[]"
      @uploader="uploadElderHealth"
      @progress="progressElderHealth"
      @upload="uploadCompleted"
      @error="uploadFail"
      :fileLimit="1"
      mode="advanced"
      :customUpload="true"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :maxFileSize="maxFileSize"
      chooseLabel="เลือกไฟล์เพื่อนำเข้าข้อมูล"
      uploadLabel="เริ่มทำการนำเข้าข้อมูล"
      cancelLabel="ยกเลิกการนำเข้าข้อมูล"
      :invalidFileSizeMessage="
        'ไม่อนุญาตให้อัพโหลดไฟล์ที่มีขาดเกิน  ' + bytestoMb + ' MB ได้ครับ'
      "
    >
      <template #empty>
        <p>
          ลากและวางไฟล์มาที่นี่เพื่ออัปโหลด ขนาดไฟล์ไม่เกิน {{ bytestoMb }} MB
        </p>
      </template>
    </UploadFile>
    <ProgressBar
      mode="indeterminate"
      style="height: 0.5em"
      v-if="isUploading"
    />
    <div class="p-col-12 p-sm-4">
      <Button
        label="ดาวน์โหลดแบบฟอร์มนำเข้าข้อมูล (.xlsx) : ข้อมูลสุขภาพ"
        icon="pi pi-download"
        class="p-button p-button-success"
        @click="() => downloadFile('Health.zip')"
      />
    </div>
  </Fieldset>
</template>

<script>
import UploadFile from '@/components/util/UploadFile'
import { mixins } from '../Mixins'

export default {
  mixins: [mixins],
  name: 'elderImportHealth',
  layout: 'Private',
  data() {
    return {
      selectedTable: 'Accident',
      table: [
        { name: 'Accident', value: 'Accident' },
        { name: 'Chronic', value: 'Chronic' },
        { name: 'Chronicfu', value: 'Chronicfu' },
        { name: 'CommunityService', value: 'CommunityService' },
        { name: 'DiagnosisIpd', value: 'DiagnosisIpd' },
        { name: 'DiagnosisOpd', value: 'DiagnosisOpd' },
        { name: 'Disability', value: 'Disability' },
        { name: 'DrugOpd', value: 'DrugOpd' },
        { name: 'Functional', value: 'Functional' },
        { name: 'Home', value: 'Home' },
        { name: 'Icf', value: 'Icf' },
        { name: 'Ncdscreen', value: 'Ncdscreen' },
        { name: 'Nutrition', value: 'Nutrition' },
        { name: 'Person', value: 'Person' },
        { name: 'Rehabilitation', value: 'Rehabilitation' },
        { name: 'Service', value: 'Service' },
        { name: 'Specialpp', value: 'Specialpp' }
      ],
      isUploading: false,
      maxFileSize: 5242880 // bytes : 10485760 => 10 MB , 5242880 => 5MB ,  524288 => 0.5 MB
    }
  },
  computed: {
    bytestoMb() {
      return this.maxFileSize / 1024 / 1024
    }
  },
  mounted() {
    console.log('on loader ::==')
  },

  async created() {
    // save activity log
    const {
      codeId: userId,
      userLevel,
      area
    } = this.$store.getters['oauth/session']
    await this.$store.dispatch('activity/saveActionActivityLog', {
      actMenu: 'IMPORT_HEALTH'
    })
  },
  methods: {
    uploadCompleted(event) {
      const { files } = event
      console.log('complete uploaded files ::==', files)
    },
    uploadFail(event) {
      const { files } = event
      console.log('error uploaded files ::==', files)
    },
    uploadedElderHealth(event) {
      const { files } = event
      console.log('uploaded files ::==', files)
    },
    progressElderHealth(event) {
      const { originalEvent, progress } = event
      console.log('progress originalEvent ::==', originalEvent)
      console.log('progress progress ::==', progress)
    },
    async uploadElderHealth(event) {
      const { files } = event
      console.log('upload files ::==', files)
      const formData = new FormData()
      formData.append('file', files[0])
      this.isUploading = true
      const table = this.selectedTable
      const { status, code, data } = await this.$store.dispatch(
        'elderly/uploadFileHealth',
        {
          payload: formData,
          tableDropdown: table
        }
      )
      this.isUploading = false
      if (code == 200) {
        this.$toast.add({
          severity: 'success',
          summary: 'สถานะการนำเข้าข้อมูล',
          detail: 'นำเข้าข้อมูลสู่ระบบเรียบร้อย',
          life: 3000
        })
        location.reload()
        setTimeout(() => {
          //this.$router.push({ path: '/elder/list' })
        }, 2000)
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'error',
          detail: 'File Uploaded',
          life: 3000
        })
      }
    },
    beforeSendUploadElderHealth(event) {
      const { xhr, formData } = event
      console.log('beforeSend xhr ::==', xhr)
      console.log('beforeSend formData ::==', formData)
    }
  },
  components: {
    UploadFile: UploadFile
  }
}
</script>

<style>
.p-fileupload-buttonbar button:nth-child(2),
.p-fileupload-buttonbar button:nth-child(2):hover {
  background-color: #427505;
}
.p-fileupload-buttonbar button:nth-child(3),
.p-fileupload-buttonbar button:nth-child(3):hover {
  background-color: #c02b2f;
}
</style>